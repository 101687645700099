import { Link, makeStyles, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { VerifiedUser } from '@material-ui/icons'
import { BookingServicesOptions } from 'helpers/forms'
import { isEmpty, isFunction, isNil } from 'lodash'
import { RoutePaths } from 'constants/globals'
import { replacePathParam } from 'utils/replacePathParam'
import { defaultNetworkOptions } from 'modules/autocompletes/NetworksAutocompleteBox'
import getFormattedDate from 'utils/getFormattedDate'
import getFormattedDateTime, { getFormattedDateSecondsTime } from 'utils/getFormattedDateTime'
import { StudioContactTypes } from '../services/types'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  listItem: {
    padding: '0px',
  },
  listitemIcon: {
    marginLeft: '-12px',
  },
  listItemIconSvg: {
    width: '0.65em',
    color: 'green',
  },
}))

const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...'
}

export const GetFormattedByType = ({ item, col }) => {
  const classes = useStyles()
  let value = col.displayId ? item[col.displayId] : item[col.id]
  if (col.backupId && isNil(value)) {
    value = item[col.backupId]
  }
  const type = col.type

  if (isNil(value)) {
    return '—'
  }

  if (isFunction(type)) {
    return type(item)
  }

  switch (type) {
    case 'date':
      return (
        <Typography variant="body2" color="textSecondary">
          {getFormattedDate(value)}
        </Typography>
      )
    case 'datepicker':
      return (
        <Typography variant="body2" color="textSecondary">
          {getFormattedDate(value)}
        </Typography>
      )
    case 'datetime':
      return (
        <Typography variant="body2" color="textSecondary">
          {getFormattedDateTime(value) + ' UTC'}
        </Typography>
      )
    case 'datetimeSeconds':
      return (
        <Typography variant="body2" color="textSecondary">
          {getFormattedDateSecondsTime(value) + ' UTC'}
        </Typography>
      )
    case 'creditToDollarConvention':
      return (
        <Typography variant="body2" color="textSecondary">
          {value * col.perCreditPrice}
        </Typography>
      )
    case 'currency':
      return (
        <Typography variant="body2" color="textSecondary">
          ${value}
        </Typography>
      )
    case 'tel':
      return <Link href={`tel:${value}`}>{value}</Link>
    case 'email':
      return <Link href={`mailto:${value}`}>{value}</Link>
    case 'user_name_link':
      return <Link href={replacePathParam(RoutePaths.USERS_DETAILS, item.user_id)}>{item.user_name ?? ''}</Link>
    case 'studio_group':
      return (
        <Link href={replacePathParam(RoutePaths.STUDIO_GROUPS_DETAILS, item?.studio_group?.id)}>
          {item?.studio_group?.id} {item?.studio_group?.name}
        </Link>
      )
    case 'link':
      return (
        <Link href={value} target="_blank">
          {value}
        </Link>
      )
    case 'truncatedlink':
      return (
        <Link href={value} target="_blank">
          {truncateString(value, 15)}
        </Link>
      )
    case 'textarea':
      return (
        <Typography className={classes.root} color="textSecondary">
          {value}
        </Typography>
      )
    case 'binary':
    case 'boolean':
      return (
        <Typography variant="body2" color="textSecondary">
          {value ? 'Yes' : 'No'}
        </Typography>
      )
    case 'reverse_boolean':
      return (
        <Typography variant="body2" color="textSecondary">
          {!value ? 'Yes' : 'No'}
        </Typography>
      )
    case 'booleanWithConfirm':
      return (
        <Typography variant="body2" color="textSecondary">
          {value ? 'Yes' : 'No'}
        </Typography>
      )
    case 'booking_service':
      return (
        <Typography variant="body2" color="textSecondary">
          {BookingServicesOptions.find((option) => option.value === value)?.label ?? '—'}
        </Typography>
      )
    case 'tags':
      return (
        <Typography variant="body2" color="textSecondary">
          {!isEmpty(value) ? value.map((tag) => tag).join(', ') : '—'}
        </Typography>
      )
    case 'tagobjects':
      return (
        <Typography variant="body2" color="textSecondary">
          {!isEmpty(value) ? value.map((tag) => tag.name).join(', ') : '—'}
        </Typography>
      )
    case 'amenities':
      return (
        <Typography variant="body2" color="textSecondary">
          {!isEmpty(value) ? value.map((amenity) => amenity).join(', ') : '—'}
        </Typography>
      )
    case 'amenityobjects':
      return (
        <Typography variant="body2" color="textSecondary">
          {!isEmpty(value) ? value.map((amenity) => amenity.name).join(', ') : '—'}
        </Typography>
      )
    case 'network':
      return (
        <Typography variant="body2" color="textSecondary">
          {value?.map((item) => defaultNetworkOptions?.find((opt) => opt.id === item)?.name).join(', ') || '-'}
        </Typography>
      )
    case 'commtype': {
      const values = value.split(',')

      return (
        <List dense={true} variant="body2" color="textSecondary">
          {values.map((opt) => (
            <ListItem key={opt} className={classes.listItem}>
              <ListItemIcon className={classes.listitemIcon}>
                <VerifiedUser className={classes.listItemIconSvg} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary">
                  {StudioContactTypes[opt]?.name}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )
    }
    case 'roles': {
      return (
        <List dense={true} variant="body2" color="textSecondary">
          {value?.map((opt) => (
            <ListItem key={opt} className={classes.listItem}>
              <ListItemText>
                <Typography variant="body2" color="textSecondary">
                  {opt}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )
    }
    default:
      return (
        <Typography variant="body2" color="textSecondary">
          {String(value?.id ?? value ?? '')}
        </Typography>
      )
  }
}
